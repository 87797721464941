import { useLayoutEffect } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";

// Utils
import { responsive, rem, Color } from "../utils/style";
import renderComponents from "../utils/articles";

// Components
import PageSEO from "../components/seo/Page";
import PageHero from "../components/PageHero";
import JournalNav from "../components/journal/JournalNav";
import JournalSection from "../components/journal/JournalSection";
import ProductCategoryCards from "../components/product/ProductCategoryCards";

// Styled Elements
const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  padding: 24px 0 0;

  ${responsive.md`
    padding: 56px 0 0;
  `}
`;

const HeroSection = styled.section`
  position: relative;
  background-color: ${(p) => p.background};
`;

const TitleWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  h1 {
    font-size: ${rem(40)};
    line-height: ${rem(54)};
    letter-spacing: -1.17px;
    color: ${Color.white};
    text-align: center;

    ${responsive.sm`
      font-size: ${rem(120)};
      line-height: ${rem(120)};
      letter-spacing: -1.76px;
    `};
  }
`;

const ArticleCategory = (props) => {
  const { seo } = props;
  const { category, featuredArticles, heroImage, summary } =
    props.data.contentfulFeaturedCategory;
  const { title, slug, subcategories } = category;

  useLayoutEffect(() => {
    props.updatePageData({
      label: "Article Category",
    });
  });

  const componentCategory = category;

  return (
    <>
      <PageSEO {...seo} />
      <Wrapper>
        <>
          <HeroSection>
            <PageHero
              backgroundColor={Color.ritualBlue}
              imageMobile={heroImage}
              imageDesktop={heroImage}
            >
              <TitleWrapper>
                <h1>{title}</h1>
              </TitleWrapper>
            </PageHero>
          </HeroSection>
          <JournalNav currentCategory={slug} />
        </>

        {/* Category Featured Articles */}
        <JournalSection
          summary={summary.summary}
          className={"mt-6 mt-md-7 mb-7 mb-md-9"}
        >
          {renderComponents(featuredArticles, componentCategory)}
        </JournalSection>

        {/* Subcategory Featured Articles */}
        {subcategories &&
          subcategories.map((subcategory) => {
            if (!subcategory.featuredArticles) return null;
            return (
              <JournalSection
                category={category}
                slug={subcategory.key}
                title={subcategory.title}
                className={"mt-7 mt-9 mb-7 mb-md-9"}
              >
                {renderComponents(
                  subcategory.featuredArticles,
                  componentCategory,
                )}
              </JournalSection>
            );
          })}
        <ProductCategoryCards className={"mt-7 mt-md-9 mb-7 mb-md-9"} />
      </Wrapper>
    </>
  );
};

// TODO: Clean up featuredArticle query into fragment, also used in articles template
export const query = graphql`
  query ContentfulArticleCategoryQuery(
    $locale: String!
    $contentfulId: String!
  ) {
    contentfulFeaturedCategory(
      node_locale: { eq: $locale }
      contentful_id: { eq: $contentfulId }
    ) {
      contentful_id
      heroImage {
        description
        gatsbyImageData(
          layout: CONSTRAINED
          placeholder: DOMINANT_COLOR
          width: 1920
          quality: 100
        )
      }
      summary {
        summary
      }
      category {
        slug
        title
        subcategories {
          title
          key
          featuredArticles {
            __typename
            type
            alignment
            quoteTitle
            quote {
              childMarkdownRemark {
                html
              }
            }
            fullWidthImage {
              file {
                url
              }
              desktop: gatsbyImageData(
                layout: CONSTRAINED
                placeholder: DOMINANT_COLOR
                width: 1920
                quality: 100
              )
            }
            articles {
              ...featuredArticlesFragment
              heroImage {
                file {
                  url
                }
                desktop: gatsbyImageData(
                  layout: CONSTRAINED
                  width: 960
                  height: 560
                  quality: 100
                  cropFocus: LEFT
                  resizingBehavior: FILL
                )
                mobile: gatsbyImageData(
                  layout: CONSTRAINED
                  width: 960
                  height: 560
                  quality: 100
                  cropFocus: LEFT
                  resizingBehavior: FILL
                )
              }
              ...expandPrimaryContent
            }
          }
        }
      }
      featuredArticles {
        __typename
        type
        alignment
        quoteTitle
        quote {
          childMarkdownRemark {
            html
          }
        }
        fullWidthImage {
          file {
            url
          }
          desktop: gatsbyImageData(
            layout: CONSTRAINED
            placeholder: DOMINANT_COLOR
            width: 1920
            quality: 100
          )
        }
        articles {
          ...featuredArticlesFragment
          heroImage {
            file {
              url
            }
            desktop: gatsbyImageData(
              layout: CONSTRAINED
              width: 960
              height: 560
              quality: 100
              cropFocus: LEFT
              resizingBehavior: FILL
            )
            mobile: gatsbyImageData(
              layout: CONSTRAINED
              width: 960
              height: 560
              quality: 100
              cropFocus: LEFT
              resizingBehavior: FILL
            )
          }
          ...expandPrimaryContent
        }
      }
    }
    allContentfulFeaturedCategory(filter: { node_locale: { eq: $locale } }) {
      nodes {
        category {
          slug
          title
        }
      }
    }
  }
`;

export default ArticleCategory;
